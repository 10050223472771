import React, { useState, useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import httpService from '@services/httpService'
import { NEWS_RENDER_COUNT } from '@constants/appSettings'
import usePageContext from '@hooks/usePageContext'
import availableLangs from '@constants/availableLangs'
import { constructNeverchangeParam } from '@utils'

export const NewsContext = React.createContext({
  newsList: [],
  skipCount: 0,
})

const NewsContextProvider = ({ children }) => {
  const { isNewsPage, pageContext } = usePageContext()
  const { langKey } = pageContext
  const newsSubPage = pageContext['*']

  const [newsState, changeNewsState] = useState({
    newsList: [],
    openedNewsItem: null,
    skipCount: 0,
    canLoadMore: true,
    isError: false,
    isLoading: false,
  })

  const getNewsBatch = async ({ withLoadOpenedItem = false, withLoading = true } = {}) => {
    try {
      const { canLoadMore, skipCount } = newsState
      if (!canLoadMore) return []
      withLoading && changeNewsState({ ...newsState, isLoading: true })

      const responseList = await httpService.getNewsBatch({ start: skipCount, limit: NEWS_RENDER_COUNT })
      const newsList = responseList.filter(el => !!el && !!el.rankingOrder)

      if (Array.isArray(newsList)) {
        let openedNewsItem = null
        let wasNotFound = false
        if (withLoadOpenedItem) {
          const paramName = constructNeverchangeParam(availableLangs[langKey] || availableLangs.de)
          const item = newsList.find(el => el[paramName] === newsSubPage)
          if (item) {
            openedNewsItem = item
          } else {
            try {
              const queryLang = navigator.language.includes(availableLangs.en) ? availableLangs.en : availableLangs.de
              const linkToSubpage = newsSubPage.startsWith('/') ? newsSubPage.slice(1) : newsSubPage
              let fetchedItemList = await httpService.getNewsItemByNeverchangeLink(queryLang, linkToSubpage)
              if (!fetchedItemList.length) {
                // if we haven't found item, repeat request with other lang key. this is edge case for 'en' browser trying to open 'de' subpage
                fetchedItemList = await httpService.getNewsItemByNeverchangeLink(
                  queryLang === availableLangs.en ? availableLangs.de : availableLangs.en,
                  linkToSubpage,
                )
              }
              const [fetchedItem = null] = fetchedItemList
              openedNewsItem = fetchedItem
              if (!fetchedItem) wasNotFound = true
            } catch (err) {}
          }
        }
        const latestList = [...newsState.newsList, ...newsList] // expands existing array

        const figureOpenedNewsItem = () => {
          if (openedNewsItem) {
            return !openedNewsItem || openedNewsItem.error ? null : openedNewsItem
          }
          return newsState.openedNewsItem
        }

        changeNewsState({
          isLoading: false,
          skipCount: newsState.skipCount + newsList.length,
          newsList: latestList,
          canLoadMore: newsList.length === NEWS_RENDER_COUNT,
          openedNewsItem: figureOpenedNewsItem(),
          isError: (openedNewsItem && openedNewsItem.error) || wasNotFound ? true : false,
        })
        return latestList
      }
      return []
    } catch (err) {
      changeNewsState({ ...newsState, isError: true, isLoading: false })
      return []
    }
  }

  const loadNews = async ({ withLoading = true } = {}) => {
    return await getNewsBatch({ withLoading })
  }

  const setOpenedNewsItem = itemId => {
    const openedNewsItem = newsState.newsList.find(el => el.id === itemId)
    if (openedNewsItem) changeNewsState({ ...newsState, openedNewsItem })
  }

  // helpers
  const filterNewsList = ({ excludedIds = [] }) => {
    return newsState.newsList.filter(el => !excludedIds.includes(el.id))
  }
  const findNewsItemInList = itemId => newsState.newsList.find(el => el.id === itemId)
  const getNewsListCount = () => newsState.newsList.length

  // on mount
  useEffect(() => {
    ;(async () => await getNewsBatch({ withLoadOpenedItem: isNewsPage && !!newsSubPage }))()
  }, [])

  const providerValue = {
    ...newsState,
    loadNews,
    filterNewsList,
    findNewsItemInList,
    setOpenedNewsItem,
    getNewsListCount,
  }

  return <NewsContext.Provider value={providerValue}>{children}</NewsContext.Provider>
}

NewsContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.element]).isRequired,
}

export default memo(NewsContextProvider)
