import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import { GlobalStyles, generalTheme } from '@themes/stylesThemes'

const StyledThemeProvider = ({ children, isLightBgPage }) => {
  return (
    <ThemeProvider theme={generalTheme}>
      {children}
      <GlobalStyles isLightBgPage={isLightBgPage} />
    </ThemeProvider>
  )
}

StyledThemeProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.element]).isRequired,
  isLightBgPage: PropTypes.bool,
}

StyledThemeProvider.defaultProps = {
  isLightBgPage: false,
}

export default StyledThemeProvider
