export const SCROLL_PROGRESS_BAR_HEIGHT = 12 // height of horizontal scroll progress bar

export const disclamerPagesPaths = {
  imprint: 'imprint',
  privacyPolicy: 'privacy',
}

export const webinarPagesPaths = {
  webinarOct2020: 'webinar-marketing-2020',
}

export const lightBgPagesPaths = {
  ...disclamerPagesPaths,
  news: 'news',
  ...webinarPagesPaths,
}

export const NEWS_API_HOST = 'https://content.infludata.com'
export const NEWS_API_ENDPOINT = `${NEWS_API_HOST}/we-create-news`

export const NEWS_RENDER_COUNT = 9 // default number of loaded news, also used for loading more(paginate)

export const LOCALIZED_ARTICLE_PROPNAMES = {
  shortDescription: 'shortDescription',
  headline: 'headline',
  buttonText: 'buttonText',
  content: 'content',
}

export const PASSED_DATES = {
  // today: 1603376298760, // used for testing
  oct24th: 1603486800000, // in ms 24th October 00:00:00
}
