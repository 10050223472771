import React, { useState } from 'react'
import PropTypes from 'prop-types'
import availableLangs from '@constants/availableLangs'

export const LocalizationContext = React.createContext({
  currentLang: '',
  labelStrings: {},
  changeCurrentLang: () => {},
})

const LocalizationProvider = ({ children, localizations, defaultLang }) => {
  const currentPageLang = availableLangs[defaultLang] || availableLangs.en
  localizations.setLanguage(currentPageLang)
  const [currentLang, setCurrentLang] = useState(currentPageLang)

  const changeCurrentLang = lang => {
    const newLang = availableLangs[lang] || availableLangs.de // with default german lang
    localizations.setLanguage(newLang)
    setCurrentLang(localizations.getLanguage())
  }

  const { labelStrings } = localizations

  const providerValue = {
    currentLang,
    labelStrings,
    changeCurrentLang,
  }
  return <LocalizationContext.Provider value={providerValue}>{children}</LocalizationContext.Provider>
}

LocalizationProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.element]).isRequired,
  localizations: PropTypes.object.isRequired,
  defaultLang: PropTypes.string,
}

LocalizationProvider.defaultProps = {
  defaultLang: availableLangs.en,
}

export default LocalizationProvider
