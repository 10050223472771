import React, { useState, useEffect, memo } from 'react'
import PropTypes from 'prop-types'

export const PageContext = React.createContext({
  pageContext: {},
})

const PageContextProvider = ({ children, pageContext: newPageContext = {} }) => {
  const [pageContext, setPageContext] = useState(newPageContext)

  useEffect(() => {
    const { location = {} } = newPageContext || {}
    if (location.pathname !== pageContext.location.pathname) {
      setPageContext(newPageContext)
    }
  }, [newPageContext])

  const providerValue = {
    pageContext,
  }

  return <PageContext.Provider value={providerValue}>{children}</PageContext.Provider>
}

PageContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.element]).isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default memo(PageContextProvider)
