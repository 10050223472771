import { NEWS_API_ENDPOINT } from '@constants/appSettings'

class HttpService {
  constructor() {
    // add base props here
    this.newsEndpoint = NEWS_API_ENDPOINT
  }

  async getRequest(endpoint) {
    const response = await fetch(endpoint, {
      method: 'GET',
    })
    const data = await response.json()
    return data
  }

  async getAllNews() {
    return await this.getRequest(this.newsEndpoint)
  }

  async getNewsBatch({ start, limit }) {
    return await this.getRequest(
      `${this.newsEndpoint}?_sort=rankingOrder:ASC,date:DESC&_start=${start}&_limit=${limit}`,
    )
  }

  async getNewsItemById(itemId) {
    return await this.getRequest(`${this.newsEndpoint}/${itemId}`)
  }

  async getNewsItemByParam({ paramName, paramValue }) {
    return await this.getRequest(`${this.newsEndpoint}?${paramName}=${paramValue}`)
  }

  async getNewsItemByNeverchangeLink(lang, link) {
    return await this.getRequest(`${this.newsEndpoint}?url_${lang}_neverchange_contains=${link}`)
  }
}

export default new HttpService()
