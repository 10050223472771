import { useContext, useMemo } from 'react'
import { PageContext } from '@providers/PageContextProvider'
import { lightBgPagesPaths } from '@constants/appSettings'

const usePageContext = () => {
  const { pageContext } = useContext(PageContext)

  const { slug: pagePath } = pageContext
  const isNewsPage = pagePath && pagePath.includes('news')

  const context = useMemo(() => {
    return {
      pageContext,
      isLightBgPage: pagePath && Object.values(lightBgPagesPaths).some(p => pagePath.includes(p)),
      isNewsPage,
    }
  }, [pageContext])

  return context
}

export default usePageContext
