import availableLangs from '@constants/availableLangs'
import { NEWS_API_HOST } from '@constants/appSettings'

export const throttled = (fn, delay = 1000) => {
  let lastCall = 0
  return (...rest) => {
    const now = Date.now()

    if (now - lastCall < delay) {
      return
    }
    lastCall = now
    return fn(...rest)
  }
}

export const debounced = (fn, delay = 1000) => {
  let timerId
  return (...args) => {
    if (timerId) {
      clearTimeout(timerId)
    }
    timerId = setTimeout(() => {
      fn(...args)
      timerId = null
    }, delay)
  }
}

export const delayFunc = (func, time = 200) => {
  return new Promise(resolve => {
    setTimeout(() => resolve(), time)
  }).then(() => func())
}

export const checkEmailValidation = email => {
  if (email.includes('+')) return false
  const EMAIL_VALID_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return EMAIL_VALID_REGEX.test(String(email).toLowerCase())
}

export const getFullPageHeight = () => (document ? document.documentElement.scrollHeight : 1)
export const getPageScrollHeight = () => (document ? document.documentElement.clientHeight : 1)
export const getPageCurrentScrollValue = () => (window ? window.pageYOffset : 1)

export const reformatDate = (dateStr = '', separator = '.') => {
  if (!dateStr) return ''
  return dateStr.split('-').reverse().join(separator)
}

export const getLocalizedArticleProp = ({ propName = '', currentLang = availableLangs.de, item = {} }) => {
  if (!propName) return ''
  if (currentLang === availableLangs.de) return item[`${propName}DE`] || item[`${propName}EN`]
  if (currentLang === availableLangs.en) return item[`${propName}EN`] || item[`${propName}DE`]
}

export const getArticleLogoSrc = imageLogoLanding => {
  if (!imageLogoLanding || !imageLogoLanding.length) return null
  const [logoItem] = imageLogoLanding
  const { url: subUrl } = logoItem || {}
  return subUrl ? `${NEWS_API_HOST}${subUrl}` : null
}

export const getCurrentDateMS = () => Date.now()

export const constructNeverchangeParam = currentLang => `url_${currentLang}_neverchange`

export const createNewsSubPageNeverchangeLink = ({ currentLang, newsItem }) => {
  const subpage = newsItem[constructNeverchangeParam(currentLang)]
  return `/news${subpage.startsWith('/') ? subpage : `/${subpage}`}`
}
