import React from 'react'
import PropTypes from 'prop-types'
import MainProvider from '@providers'

export const wrapPageElement = ({ element, props }) => <MainProvider {...props}>{element}</MainProvider>

wrapPageElement.propTypes = {
  element: PropTypes.oneOfType([PropTypes.element, PropTypes.node]).isRequired,
  props: PropTypes.object.isRequired,
}
