import LocalizedStrings from 'react-localization'
import availableLangs from '@constants/availableLangs'
import labelStrings from './labelStrings'

const localizations = new LocalizedStrings({
  [availableLangs.en]: {
    labelStrings: labelStrings[availableLangs.en],
  },
  [availableLangs.de]: {
    labelStrings: labelStrings[availableLangs.de],
  },
})

export default localizations
