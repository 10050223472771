import { createGlobalStyle } from 'styled-components'
import { generateMedia } from 'styled-media-query'
import windowSizes from '@constants/windowSizes'
import { isMobile } from 'react-device-detect'

const media = generateMedia({
  desktop: `${windowSizes.desktop}px`,
  mid: `${windowSizes.mid}px`,
  tablet: `${windowSizes.tablet}px`,
  smallTablet: `${windowSizes.smallTablet}px`,
  smallDesktop: `${windowSizes.smallDesktop}px`,
  mobile: `${windowSizes.mobile}px`,
})

// add more styles global here
export const generalTheme = {
  media,
  color: {
    white: '#ffffff',
    black: '#000000',
    woodsmoke: '#0A0C0C',
    white50: '#ffffff80',
    white90: '#ffffffE6',
    white70: 'rgba(255, 255, 255, 0.7)',
    white35: 'rgba(255, 255, 255, 0.35)',
    white15: 'rgba(255, 255, 255, 0.15)',
    semiWhite: '#FFF8EC',
    yellow: '#FFCA55',
    softYellow: '#FFC400',
    textColor: '#363636',
    textLightColor: '#707070',
    textDarkColor: '#292929',
    gray: '#888888',
    darkGray: '#2F2F2F',
    darkGray50: '#2F2F2F80',
    midGray: '#e0e0e0',
    lightGray: '#f5f5f5',
    milk: '#fef5f2',
    cerise: `#DA2BB7`,
    salmon: '#FF7C6C',
    green: '#3ED253',
    pizazz: '#FF9300',
    gallery: '#EFEFEF',
    codGray: '#1C1C1C',
    ironsideGray: '#6B6A67',
    red: '#F14179',
    mineShaft: '#242424',
    silver: '#B9B9B9',
    cornflowerBlue: '#597DF7',
    alabaster: '#F8F8F8',
    cornflowerBlue2: '#6558FF',
    athensGray: '#F5F5F8',
  },
  fontFamily: {
    inter: "'Inter', sans-serif",
    brush: 'BrushUpToo',
  },

  flex: {
    display: 'display: flex;',
    centerAll: `
      display: flex;
      justify-content: center;
      align-items: center;
      `,
    column: `
    display: flex;
    flex-direction: column;
    `,
    centerAllColumn: `
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    `,
    wrap: `
      display: flex;
      flex-wrap: wrap;
    `,
  },
  gradient: {
    progressbar: 'transparent linear-gradient(90deg, #FFC400E6 0%, #F14179E6 100%) 0% 0% no-repeat padding-box;',
    hero: 'transparent radial-gradient(closest-side at 42% 35%,#3b362f 0%,#0a0c0c 100%) 0% 0% no-repeat padding-box',
    artists: 'transparent linear-gradient(159deg, #BD16E1 0%, #FF477E 100%) 0% 0% no-repeat padding-box;',
    infludata:
      'transparent radial-gradient(circle at 55% -50%, #FF726F 0%, #FF726F 33%, #FFCA55 100%) 0% 0% no-repeat padding-box;',
    marketing: 'transparent linear-gradient(64deg, #3ED253 0%, #FFD60D 100%) 0% 0% no-repeat padding-box;',
    education: 'transparent linear-gradient(51deg, #FF9300 0%, #FFF500 100%) 0% 0% no-repeat padding-box;',
    campaigns: 'transparent linear-gradient(57deg, #EC5796 0%, #EE1E41 47%, #F29322 100%) 0% 0% no-repeat padding-box;',
    news: 'transparent linear-gradient(24deg, #576EEC 0%, #22DAF2 100%) 0% 0% no-repeat padding-box;',
    contactButton: 'transparent linear-gradient(97deg, #FEB80C 0%, #F14277 100%) 0% 0% no-repeat padding-box;',
  },
  withTransition: `
    transition: all 0.3s ease-in-out;
    transition-delay: 0.1s;
  `,
  withZoom: `
    transform: scale3d(1.05, 1.05, 1);
    transform-style: preserve-3d;
    @media screen and (max-width: 1024px) {
      transform: ${(() => (isMobile ? 'unset' : 'transform: scale3d(1.05, 1.05, 1)'))()};
    }
  `,
  withTinyZoom: `
    transform: scale3d(1.025, 1.025, 1);
    transform-style: preserve-3d;
    @media screen and (max-width: 1024px) {
      transform: ${(() => (isMobile ? 'unset' : 'transform: scale3d(1.025, 1.025, 1)'))()};
    }
  `,

  bw: {
    enable: `
      -webkit-filter: grayscale(1); /* Safari 6.0 - 9.0 */
      filter: grayscale(1);
    `,
    disable: `
     -webkit-filter: grayscale(0);
     filter: none;
     `,
  },
}

export const GlobalStyles = createGlobalStyle`
  html, body {
    overflow-x: hidden;
  }
  html, body, div, p {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  body {
    background-color: ${props => (props.isLightBgPage ? generalTheme.color.gallery : generalTheme.color.woodsmoke)};
    scroll-behavior: smooth;
    & > * {
      font-family: ${generalTheme.fontFamily.inter};
      color: ${props => (props.isLightBgPage ? generalTheme.color.codGray : generalTheme.color.white)};
    }
    &::-webkit-scrollbar {
      // scroll is hidden
      // width: 4px;
      // background-color: ${generalTheme.color.gray};
      width: 0px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      // background-color: ${generalTheme.color.softYellow};
      background-color: transparent;
    }
  }
  a, a:active, a:focus {
    text-decoration: none;
    color: inherit;
   
  }

`
