import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import smoothscroll from 'smoothscroll-polyfill'
import LocalizationProvider from '@providers/LocalizationProvider'
import localizations from '@localization'
import StylesThemeProvider from '@providers/StylesThemeProvider'
import { lightBgPagesPaths } from '@constants/appSettings'
import PageContextProvider from '@providers/PageContextProvider'
import NewsContextProvider from '@providers/NewsProvider'

const MainProvider = ({ children, pageContext, ...rest }) => {
  const { langKey: pageLang, slug: pagePath } = pageContext || {}
  const isLightBgPage = pagePath && Object.values(lightBgPagesPaths).some(p => pagePath.includes(p))
  useEffect(() => {
    smoothscroll.polyfill()
  }, [])

  const { navigate, ...restOfPageProps } = rest // exclude navigate func
  const pageContextForProvider = { ...pageContext, ...restOfPageProps }
  return (
    <PageContextProvider pageContext={pageContextForProvider}>
      <NewsContextProvider>
        <StylesThemeProvider isLightBgPage={isLightBgPage}>
          <LocalizationProvider defaultLang={pageLang} localizations={localizations} key={pageLang}>
            {children}
          </LocalizationProvider>
        </StylesThemeProvider>
      </NewsContextProvider>
    </PageContextProvider>
  )
}

MainProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default MainProvider
