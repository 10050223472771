import availableLangs from '@constants/availableLangs'

/**
 * NOTE:
 * THIS FILE INCLUDES ALL TRANSLATIONS (except disclamer pages)
 * AND SOME PAGE META DATA - title, description, keywords
 * TODO: modify and add all translations here
 * since en version is not introduced yet, german is used for english strings. When en version is about to introduce, just replace texts to english
 */

export default {
  [availableLangs.en]: {
    pageTitle: 'weCreate | Agency for TikTok and Vertical Video Marketing',
    pageKeywords:
      'tiktok, youneszarou, nickaufmann, influencer marketing, creator marketing, agency, herranwalt, instagram, infludata, vertical video, agentur',
    pageDescription:
      'Agency for innovative creator marketing on TikTok and Instagram. We are experts in Vertical Video and GenZ Marketing.',
    cookieMsg: 'This website uses cookies to enhance the user experience.',
    accept: 'Accept',
    decline: 'Decline',
    imprint: 'Imprint',
    privacyPolicy: 'Privacy Policy',
    termAndConditions: 'Terms and Conditions',
    copyright: 'weCreate Copyright 2020',
    weAreHiring: 'We are hiring',
    weCreate: 'weCreate',
    bible: 'Bible',
    germany: 'Germany',
    international: 'International',
    ourArtists: 'Our Artists',
    download: 'Download',
    backToTop: 'Back To Top',
    send: 'Send',
    sending: 'Sending...',
    thankyou: 'Thank you!',
    emailAddress: 'E-mail address',
    name: 'Name',
    company: 'Company',
    opt: 'opt.',
    message: 'Message',
    notValidEmail: 'Not a valid e-mail address.',
    notValidName: 'Please type your name.',
    notValidMessage: 'Please write minimum 60 characters.',
    weWillComeBacktoYou: 'We will come back to you as soon as possible!',
    sorry: 'Sorry...',
    errorMsg: 'An error has occured. We are working on it. Please try again later.',
    loadMore: 'Load More',
    inCollaboration: 'in collaboration with',
    articleLoadErrorMsg: "Sorry, the article can't be loaded. Please check if the link is correct or try again later",

    author: 'Author',
    loading: 'Loading...',
    loadMoreNewsError: 'Sorry, loading news failed. Please try again',
    tryAgain: 'Try Again',
    moreActicles: 'More Articles',
    hero: {
      title: 'Agency for TikTok and Vertical Video Marketing',
      text:
        'weCreate is the leading TikTok and Vertical Video Agency in Germany and Austria. Our work is driven by passion, creativity, market insights and a fondness for data.',
      markedText:
        'This enables us to ideally support our clients – whether that may be for Creator Marketing, Content Creation, Strategy or Campaigns.',
    },
    artists: {
      title: 'Artists',
      text:
        'All of our artists initially gained their community through TikTok, which makes them the perfect partner for building a Gen Z brand. And more importantly, each of them has their own unique history and stands for sustainability and values.',
      becomePartOfWeCreate: 'You are a creator? Become part of our weCreate network!',
      monetize: 'I am a TikTok creator and I want to monetize my reach. (only German speaking)',
      exclusiveCollab: 'I am a TikTok creator interested in an exclusive collaboration.',
      interestedInCollaboration: 'You are interested in a collaboration with our creators?',
      over200KFollowers: '>from 200.000 Followers',
      forApplication: 'For application',
      contactUs: 'Contact Us',
    },
    infludata: {
      title: 'influData',
      text:
        'Our search engine finds up to 7 million Instagram and TikTok Creators and delivers the best individual audience reports and quality analyzes. Intelligent campaign tracking keeps track of links and hashtags in stories and posts and provides feedback on performance.',
      tryInfludataForFree: 'You can try influData for free!',
      tryInfludata: 'Try influData',
      rankings: {
        desc: 'We provide up-to-date TikTok rankings by views and followers',
        btn: 'TikTok Rankings',
      },
    },
    marketing: {
      title: 'TikTok Marketing',
      subtitle: 'for companies and brands',
      texts: {
        partOne:
          'We are convinced that brands can only provide their social media channels with enough relevant content by building creator communities. We do not think in short term campaigns, but in finding suitable long term brand ambassadors and creating sustainable and relevant content formats for the target group.',
        partTwo:
          'The advertising sensitivity of Gen Z is increasing, the future lies in adding real value and content that is interesting for the target group. This is only possible if give enough freedom to creators.',
      },
      learnMore: 'Contact Us',
      weAreinterested: 'We are a brand or company and interested in working with weCreate',
      credentials: 'References',
    },
    education: {
      title: 'Social Media Bibles and Reports',
      freeBibleAndReports:
        'Our free social media bibles and TikTok data reports have been downloaded thousands of times. ',
      reports: {
        title: 'Reports',
        may2020: 'Report May 2020 (German)',
        june2020: 'Report June 2020 (German)',
        august2020: 'Report August 2020 (German)',
      },
      bibles: {
        title: 'Bibles',
        tiktok: 'TikTok Bible (German)',
        facebook: 'Facebook Bible (English)',
      },
    },
    campaigns: {
      description:
        'With reach come responsibility. This is why we regularly campaign for important non-commercial topics.',
      blm: {
        title: 'BLACK LIVES MATTER',
        hashtag: '#blacklivesmatter',
        hashtagLink: 'https://www.tiktok.com/tag/blacklivesmatter',
        desc:
          'Our lives begin to end the day we become silent about things that matter.” - Dr. Martin Luther King, Jr.',
        results: 'Result: 16 creator videos, 229 UGC videos, 16 million views, 3,9 million likes, 14,500 comments',
      },
      prideMonth: {
        title: 'PRIDE MONTH',
        hashtag: '#kisschain',
        hashtagLink: 'https://www.tiktok.com/tag/kisschain',
        desc:
          "We want to be allies of the LGBTQIA+ movement so one day you don't have to fight for the right to be whoever you want to be.",
        results: 'Result: 28 creator videos, 891 UGC videos, 3,2 million views, 900K likes, 15,000 comments',
      },
    },
    news: {
      weCallOurselves:
        "We call ourselves experts in Tiktok Creation and TikTok Marketing - and that's how we are also viewed by the major German-speaking media.",
      numerousInterviews:
        'In numerous interviews, we gave, among other things, estimates about the further development of TikTok and other social media channels, about the growth of our creators or the functionality of the platform.',
    },
    contactUs: {
      title: 'Contact Us',
      subtitle: 'We are here for you.',
      text: 'Please write below your question and we will come back to you. We usually respond in few hours.',
    },
    webinarOct2020: {
      join: {
        desc: "Masterclass: Get over 7 hours content from Germany's influencer experts",
        btn: 'To the Masterclass',
        stickyBtn: "Don't miss: Influencer Marketing Webinar on October 23.",
      },

      bookASpot: 'Book A Spot',
      buyMasterclass: 'Masterclass kaufen',
      presentation: {
        title: 'Influencer Marketing Fest',
        byAuthors: 'mit Adil Sbai & Sarah Emmerich',
        date: 'Freitag, 23. Oktober 2020',
        playtime: 'Playtime: über 7 Stunden',
        timeline: '9:00 - 16:45',
        type: 'Zoom Webinar',
        masterclass: 'Vimeo Masterclass',
        accessToMasterclass: "Schau' wo du willst und wie oft du willst. Lifetime-Zugang zur Masterclass",
        buyMasterclass: 'Masterclass kaufen',
        lang: 'Deutsch',
        videoRec: 'Keine Zeit? Aufzeichung inbegriffen',
        price: '199,00 EUR',
        exkl: 'exkl. USt',
        desc: {
          firstParagraph:
            'Influencer Marketing ist ungeheuer vielseitig und effektiv, gleichzeitig aber auch enorm herausfordernd und fehleranfällig. Zu wissen, wann und wie man Influencer Marketing einsetzt, kann schwierig und verwirrend sein.',
          secondParagraph:
            'Mit unserem Influencer Marketing Fest wollen wir Licht ins Dunkel bringen und das Thema aus verschiedenen Perspektiven durchleuchten – unterstützt von den angesagtesten Speakern und Experten zu TikTok, Instagram, YouTube und LinkedIn.',
          thirdParagraph:
            'Ganz gleich, ob du ein Markenvertreter, Marketer oder Creator bist – beim Influencer Marketing Fest wirst du lernen und verstehen, wie nachhaltiges Influencer Marketing plattformübergreifend funktioniert.',
          fourthParagraph:
            'Das Influencer Marketing Fest mit renommierten Speakern aus verschiedenen Branchen wird dir eine Vielzahl wertvoller Einsichten zum Einsatz von Influencer Marketing in Theorie und Praxis geben.',
          list: [
            'Unsere Redner sind Creator, Marketingspezialisten und Markenvertreterinnen, die die Social-Media-Landschaft in den letzten Jahren maßgeblich geprägt haben.',

            'Das Webinar wird Diskussionen zum Thema Influencer Marketing auf Social-Media-Plattformen wie YouTube, Instagram, TikTok und LinkedIn beinhalten.',

            'Zusätzlich werfen wir einen Blick auf das Influencer Marketing im B2B-Bereich und auf Influencer-Events.',
          ],
        },
      },
      refs: 'References',
      inTheNews: 'In The News',
      speakers: {
        title: 'Speakers',
        moderators: {
          title: 'Hosts',
          list: [
            {
              firstName: 'Adil',
              lastName: 'Sbai',

              links: {
                instagram: 'https://www.instagram.com/sbaiderman/?hl=en',
                tiktok: 'https://www.tiktok.com/@sbaiderman',
                linkedin: 'https://at.linkedin.com/in/adil-sbai-500b75117',
                youtube: 'https://www.youtube.com/channel/UCl4BRUbFKQTt-NAlqd9XE2g',
              },
              roles: [
                'CEO und Gründer von weCreate, der ersten datenbasierterten TikTok Agentur',
                'Autor der TikTok-Bibel ',
                'Manager von Younes Zarou, dem größten Creator Europas',
                'TikTok-Marketing- und Strategie-Experte',
              ],
              imagename: 'adil-s.jpg',
            },
            {
              firstName: 'Sarah',
              lastName: 'Emmerich',
              links: {
                instagram: 'http://www.instagram.com/sarahemmerich',
                linkedin: 'https://de.linkedin.com/in/sarahemmerichinfluencer',
              },
              roles: [
                'Expertin für Influencer Marketing',
                'Social-Media-Native und Creator',
                'Podcast Host von “Zwischen Generation Y und Z”',
                'Strategin & Beraterin rund um das Thema Social Media mit Fokus auf Influencer Marketing & Personal Branding',
              ],
              imagename: 'sarah-e.jpg',
            },
          ],
        },
        brands: {
          title: 'Brands',
          list: [
            {
              firstName: 'Johannes',
              lastName: 'Kliesch',
              links: {
                linkedin: 'https://www.linkedin.com/in/johannes-kliesch/',
                instagram: 'https://www.instagram.com/johannes.snocks/?hl=de',
                youtube: 'https://www.youtube.com/channel/UCH5aAB27lz57Ca7MlJya9QA',
              },
              roles: [
                'Gründer und Geschäftsführer von SNOCKS',
                'E-Commerce Speaker für Amazon FBA und Shopify',
                'Zweifach ausgezeichnet als Forbes 30 Under 30',
              ],
              imagename: 'johannes-k.jpg',
            },
          ],
        },
        creators: {
          title: 'Creators',
          list: [
            {
              firstName: 'Max',
              lastName: 'Klockenhoff',
              links: {
                linkedin: 'https://www.linkedin.com/in/max-klockenhoff/',
                instagram: 'https://www.instagram.com/weltfaktende/?hl=en',
                youtube: 'https://www.youtube.com/channel/UCohZCZUm6uP6OdDQuRsMOIw',
              },
              roles: [
                'Influencer Relations & Social Media Berater',
                'TikTok-Experte',
                'Ex-Creator (YouTube & Instagram)',
              ],
              imagename: 'max-k.jpg',
            },
            {
              firstName: 'Herr',
              lastName: 'Anwalt',
              links: {
                instagram: 'https://www.instagram.com/herr_anwalt/?hl=en',
                tiktok: 'https://www.tiktok.com/@herranwalt',
                youtube: 'https://www.youtube.com/channel/UCOfQ0nZG7YClwNSaL-xULKQ',
              },
              roles: [
                'Rechtsanwalt und Fachanwalt bei Besler & Walter & Keuneke',
                'TikTok-Creator mit 2,5 Millionen Followern',
                'Mercedes-Benz Kooperationspartner',
              ],
              imagename: 'herranwalt.jpg',
            },
          ],
        },
        marketers: {
          title: 'Marketers',
          list: [
            {
              firstName: 'Céline Flores',
              lastName: 'Willers',
              links: {
                linkedin: 'https://www.linkedin.com/in/céline-flores-willers/',
                instagram: 'https://www.instagram.com/celine.flores/?hl=de',
              },
              roles: [
                'Gründerin der The People Branding Company ',
                'Top 25 LinkedIn Influencer in Dach („Top Voice 2018/19“)',
                'Personal Branding Strategin',
                'Miss Universe Germany 2018',
              ],
              imagename: 'celine-f.jpg',
            },
            {
              firstName: 'Neil',
              lastName: 'Heinisch',
              links: {
                instagram: 'https://www.instagram.com/neilheinisch/?hl=en',
                twitter: 'https://twitter.com/NeilHeinisch',
                linkedin: 'https://www.linkedin.com/in/neilheinisch/',
              },
              roles: [
                'Mitbegründer von PlayTheHype ',
                'Generation Z-Berater und Startup Teen',
                'Marketing- und TikTok-Enthusiast',
              ],
              imagename: 'neil-h.jpg',
            },
            {
              firstName: 'Lina',
              lastName: 'Arnold',
              links: {
                linkedin: 'https://www.linkedin.com/in/linaeinberger/',
              },
              roles: [
                'Geschäftsführerin bei JOLI BERLIN',
                'Expertin für datengesteuerte Influencer Marketingkampagnen',
              ],
              imagename: 'lina-a.jpg',
            },
            // {
            //   firstName: 'Elisa',
            //   lastName: 'Buhr',
            //   links: {
            //     linkedin: 'https://www.linkedin.com/in/elisa-buhr-927755b4',
            //   },
            //   roles: [
            //     'Marketing Managerin bei Katjes Fassin GmbH + Co. KG',
            //     'Marketing Managerin für die Marken Ahoj-Brause, SALLOS und Hemptastic',
            //     'Der Geist hinter Ahoj-Brauses bisher erfolgreichster Kampagne',
            //   ],
            //   imagename: 'elisa-b.jpg',
            // },
            {
              firstName: 'Hendrik',
              lastName: 'Martens',
              links: {
                linkedin: 'https://www.linkedin.com/in/hendrikmartens/',
                instagram: 'https://www.instagram.com/ideealo/?hl=en',
              },
              roles: [
                'CMO von flow:fwd, dem Artist-Management von Simon Unge, Inscope21, UnsympathischTV, Varion u.a.',
                'Moderator des Webinars “BIZZfluencer” zum Thema Business Influencers ',
              ],
              imagename: 'hendrik-m.jpg',
            },
            {
              firstName: 'Arian',
              lastName: 'Ruß',
              links: {
                linkedin: 'https://www.linkedin.com/in/arianruß/',
                twitter: 'https://twitter.com/arian_swinx',
              },
              roles: ['CMO von swinx', 'B2B Influencer Service'],
              imagename: 'arian-r.jpg',
            },
            {
              firstName: 'Alina',
              lastName: 'Ludwig',
              links: {
                linkedin: 'https://www.linkedin.com/in/alinaludwig/',
                instagram: 'https://www.instagram.com/laalinali/',
              },
              roles: [
                'Senior Strategy Consultant bei ODALINE',
                'Podcast Host von “Influence!” ',
                'Brand Strategist & Influencer Marketing Expertin',
                'Autorin des Buches “Influence!”',
              ],
              imagename: 'alina-l.jpg',
            },
            // {
            //   firstName: 'Leon',
            //   lastName: 'Jungfleisch',
            //   links: {
            //     linkedin: 'https://www.linkedin.com/in/leon-jungfleisch-52bb8a17a/',
            //   },
            //   roles: ['Gründer & Geschäftsführer PEECES | E-Commerce'],
            //   imagename: 'leon-j.jpg',
            // },
          ],
        },
      },
      timeTable: {
        title: 'Timetable',
        content: 'Inhalt',
        list: [
          {
            time: 'Part 1',
            speaker: 'Sarah Emmerich (30 min)',
            label: 'Die Geschichte des Influencer Marketings',
          },
          {
            time: 'Part 2',
            speaker: 'Sarah Emmerich & Alina Ludwig  (90 min)',
            label: 'Kampagnenmanagement: Sourcing, Verhandlungen, Briefing, Reporting',
          },
          {
            time: 'Part 3',
            speaker: 'Arian Ruß (30 min)',
            label: 'B2B Influencer: Die neue Geheimwaffe im Social Selling auf LinkedIn?',
          },
          {
            time: 'Part 4',
            speaker: 'Céline Flores Willers (30 min)',
            label: 'Q&A: Wie kann Influencer Marketing für B2B und B2C Unternehmen auf LinkedIn funktionieren?',
          },
          {
            time: 'Part 5',
            speaker: 'Johannes Kliesch (30 min)',
            label: 'Der Value von Performance Marketing für Influencer Marketing',
          },
          {
            time: 'Part 6',
            speaker: 'Sarah Emmerich (45 min)',
            label: 'Influencer Events: Modelle und Best Practices',
          },
          {
            time: 'Part 7',
            speaker: 'Björn Wenzel (15min)',
            label: 'Die Wirkung von Influencer-Marketing: Marktforschung, Follower-Sturkturanalyse, Langzeiteffekte',
          },
          {
            time: 'Part 8',
            speaker: 'Hendrik Martens (30 min)',
            label: 'Creative Influencer Marketing auf YouTube: Best Practices national & international',
          },
          {
            time: 'Part 9',
            speaker: 'Max Klockenhoff (30 min)',
            label: 'Wann macht Influencer Marketing auf TikTok Sinn',
          },
          {
            time: 'Part 10',
            speaker: 'Adil Sbai (30 min)',
            label: 'Influencer Marketing auf TikTok: Best Practices national & international',
          },
          {
            time: 'Part 11',
            speaker: 'Lina Arnold & Jonas Kunow (30 min)',
            label: 'TikTok-Kampagnen mit ROI-Fokus',
          },
          { time: 'Part 12', speaker: 'Neil Heinisch (30 min)', label: 'Influencer Marketing für die Gen Z' },
          { time: 'Part 13', speaker: 'Herr Anwalt (30 min)', label: 'Gutes Influencer Marketing - aus Creator-Sicht' },
          { time: 'Outro', speaker: 'Sarah Emmerich & Adil Sbai', label: 'Finale' },
        ],
      },
      about: {
        title: 'about',
        desc:
          'weCreate ist die erste datenbasierte TikTok-Agentur. Wir konzentrieren uns darauf, unseren Kunden Beratung, Daten, Marktforschung und Strategie unter Verwendung unserer eigenen Creator-Datenbank, influData, anzubieten.',
        link: 'Go To WebSite',
      },
      successPurchase:
        'Vielen Dank. Du erhältst in Kürze eine Rechnung und die Zugangsdaten via Mail. Wir wünschen dir viel Spaß mit unserer Masterclass!',
    },
  },

  /**-------------------
   * GERMAN TRANSLATIONS
   * -------------------
   */

  [availableLangs.de]: {
    pageTitle: 'weCreate | Agentur für TikTok und Vertical Video Marketing',
    pageKeywords:
      'tiktok, youneszarou, nickaufmann, influencer marketing, creator marketing, agency, herranwalt, instagram, infludata, vertical video, agentur',
    pageDescription:
      'Die Agentur für innovatives Creator Marketing auf TikTok und Instagram. Wir sind Experten für Vertical Video und GenZ Marketing.',
    cookieMsg: 'Diese Website verwendet Cookies, um die Benutzererfahrung zu verbessern.',
    accept: 'Akzeptieren',
    decline: 'Ablehnen',
    imprint: 'Impressum',
    privacyPolicy: 'Datenschutzerklärung',
    termAndConditions: 'Allgemeine Geschäftsbedingungen',
    copyright: 'weCreate Copyright 2020',
    weAreHiring: 'Wir stellen ein',
    weCreate: 'weCreate',
    bible: 'Bibel',
    germany: 'Deutschland',
    international: 'International',
    ourArtists: 'Unsere Artists',
    download: 'Herunterladen',
    backToTop: 'Zurück nach oben',
    send: 'Senden',
    sending: 'Senden...',
    thankyou: 'Vielen Dank!',
    emailAddress: 'E-Mail-Adresse',
    name: 'Name',
    company: 'Unternehmen',
    opt: 'optional',
    message: 'Nachricht',
    notValidEmail: 'Keine gültige E-Mail-Adresse.',
    notValidName: 'Bitte Namen eingeben.',
    notValidMessage: 'Bitte schreibe mindestens 60 Zeichen.',
    weWillComeBacktoYou: 'Wir werden uns so schnell wie möglich bei dir melden!',
    sorry: 'Es tut uns leid...',
    errorMsg: 'Ein Fehler ist aufgetreten. Wir arbeiten daran. Bitte versuchen Sie es später noch einmal.',
    loadMore: 'Mehr laden',
    inCollaboration: 'in Zusammenarbeit mit',
    articleLoadErrorMsg:
      'Der Artikel kann leider nicht geladen werden. Bitte überprüfen Sie, ob der Link korrekt ist, oder versuchen Sie es später erneut',

    author: 'Autor',
    loading: 'Wird geladen...',
    loadMoreNewsError: 'Das Laden weiterer Nachrichten ist leider fehlgeschlagen. Bitte versuche es erneut',
    tryAgain: 'Versuch es noch einmal',
    moreActicles: 'Weitere Artikel',
    hero: {
      title: 'Agentur für TikTok und Vertical Video Marketing',
      text:
        'weCreate ist die führende TikTok- und Vertical-Video-Agentur in Deutschland und Österreich. Durch unseren direkten Zugang zur Welt der Creator können wir als Schnittstelle zwischen Gen Z und Corporates agieren.',
      markedText:
        'So sind wir in der Lage, unsere Kunden optimal zu betreuen – sei es im Bereich Creator Marketing, Content Creation, Strategy oder Kampagnen.',
    },
    artists: {
      title: 'Artists',
      text:
        'Alle unsere Künstler haben ihre Community initial durch TikTok gewonnen, was sie zum perfekten Partner für den Aufbau einer Gen Z-Marke macht. Und noch viel wichtiger: Jeder von ihnen hat seine eigene, unique Geschichte und steht für Nachhaltigkeit und Werte.',
      becomePartOfWeCreate: 'Du bist Creator? Werde ein Teil von unserem weCreate-Netzwerk!',
      monetize: 'Ich bin ein TikTok-Creator und möchte meine Reichweite zu Geld machen.',
      exclusiveCollab: 'Ich bin ein TikTok-Creator habe Interesse an einer exklusiven Zusammenarbeit.',
      interestedInCollaboration: 'Du hast Interesse an einer Zusammenarbeit mit unseren Creatorn?',
      over200KFollowers: '>ab 200.000 Follower',
      forApplication: 'Zur Bewerbung',
      contactUs: 'Kontaktiere uns',
    },
    infludata: {
      title: 'influData',
      text:
        'Unsere Suchmaschine findet bis zu 7 Millionen Instagram und TikTok Creator und liefert die besten individuellen Audience-Reports und Qualitätsanalysen. Intelligentes Kampagnentracking behält den Überblick über Verlinkungen und Hashtags in Storys und Posts und gibt Feedback zur Performance. ',
      tryInfludataForFree: 'Du kannst influData kostenlos ausprobieren!',
      tryInfludata: 'Infludata ausprobieren',
      rankings: {
        desc: 'Wir bieten aktuelle TikTok-Rankings nach Ansichten und Followern',
        btn: 'TikTok-Rangliste',
      },
    },
    marketing: {
      title: 'TikTok Marketing',
      subtitle: 'für Unternehmen und Marken',
      texts: {
        partOne:
          'Wir sind überzeugt, dass Marken nur mit dem Aufbau von Creator Communities ihre Social Media-Kanäle mit relevanten Inhalten versorgen können. Wir denken nicht in Kampagnen, sondern arbeiten gemeinsam mit unseren Kunden daran, passende Markenbotschafter zu finden, die ihn langfristig begleiten und auf diese Weise nachhaltige und für die Zielgruppe relevante Content-Formate erschaffen.',
        partTwo:
          'Die Werbesensibilität der Gen Z nimmt zu, die Zukunft liegt in realem Mehrwert und Inhalten, die die Zielgruppe interessieren. Das ist nur möglich, wenn man den richtigen zur Marke passenden Creatorn Freiheit lässt.',
      },
      learnMore: 'Kontaktiere uns',
      weAreinterested: 'Wir sind eine Marke oder ein Unternehmen und an einer Zusammenarbeit mit weCreate interessiert',
      credentials: 'Referenzen',
    },
    education: {
      title: 'Social Media-Bibeln und Reports',
      freeBibleAndReports:
        'Unsere kostenlosen Social Media-Bibeln und TikTok Daten-Reports haben sich bereits zigtausende male heruntergeladen. ',

      reports: {
        title: 'Reports',
        may2020: 'Report Mai 2020 (de)',
        june2020: 'Report Juni 2020 (de)',
        august2020: 'Report August 2020 (de)',
      },
      bibles: {
        title: 'Bibeln',
        tiktok: 'TikTok Bibel (de)',
        facebook: 'Facebook Bible (en)',
      },
    },
    campaigns: {
      description:
        'Wir sind überzeugt, dass Reichweite verpflichtet. Deshalb setzen wir uns regelmäßig für wichtige non-kommerzielle Themen ein.',
      blm: {
        title: 'BLACK LIVES MATTER',
        hashtag: '#blacklivesmatter',
        hashtagLink: 'https://www.tiktok.com/tag/blacklivesmatter?lang=en',
        desc:
          '„Unser Leben beginnt zu enden an dem Tag, an dem wir wichtige Dinge totschweigen.” Gib Rassismus keine Chance!',
        results: 'Ergebnis: 16 Creator-Videos, 229 UGC-Videos, 16 Mio. Views, 3.9 Mio. Likes, 14.500 Comments',
      },
      prideMonth: {
        title: 'PRIDE MONTH',
        hashtag: '#kisschain',
        hashtagLink: 'https://www.tiktok.com/tag/kisschain',
        desc:
          'Wir wollen Verbündete der LGBTQIA+-Bewegung sein, damit man für das Recht, zu sein, wer immer man will, eines Tages nicht mehr kämpfen muss.',
        results: 'Ergebnis: 28 Creator-Videos, 891 UGC-Videos, 3,2 Mio. Views, 900 K. Likes, 15.000 Comments',
      },
    },
    news: {
      weCallOurselves:
        'Wir bezeichnen uns als Experten für Tiktok Creation und TikTok Marketing - und so werden wir auch von den großen deutschsprachigen Medien angesehen.',
      numerousInterviews:
        'Wir haben in zahlreichen Interviews unter anderem Einschätzungen über die weitere Entwicklung von TikTok und anderen Social-Media-Kanälen, über das Wachstum unserer Creator oder die Funktionsweise der Plattform abgegeben.',
    },
    contactUs: {
      title: 'Kontaktiere uns',
      subtitle: 'Wir sind für dich da.',
      text:
        'Bitte schreiben Sie unten Ihre Frage und wir werden uns bei Ihnen melden. Wir antworten normalerweise in wenigen Stunden.',
    },
    webinarOct2020: {
      join: {
        desc: 'Masterclass: Über 7 Stunden Content von Deutschlands Influencer-Experten',
        btn: 'Zur Masterclass',
        stickyBtn: 'Nicht verpassen: Influencer-Marketing Webinar am 23.10.',
      },
      bookASpot: 'Jetzt anmelden',
      buyMasterclass: 'Masterclass kaufen',
      presentation: {
        title: 'Influencer Marketing Fest',
        byAuthors: 'mit Adil Sbai & Sarah Emmerich',
        date: 'Freitag, 23. Oktober 2020',
        playtime: 'Playtime: über 7 Stunden',
        timeline: '9:00 - 16:45',
        type: 'Zoom Webinar',
        masterclass: 'Vimeo Masterclass',
        accessToMasterclass: "Schau' wo du willst und wie oft du willst. Lifetime-Zugang zur Masterclass",
        buyMasterclass: 'Masterclass kaufen',
        lang: 'Deutsch',
        videoRec: 'Keine Zeit? Aufzeichung inbegriffen',
        price: '199,00 EUR',
        exkl: 'exkl. USt',
        desc: {
          firstParagraph:
            'Influencer Marketing ist ungeheuer vielseitig und effektiv, gleichzeitig aber auch enorm herausfordernd und fehleranfällig. Zu wissen, wann und wie man Influencer Marketing einsetzt, kann schwierig und verwirrend sein.',
          secondParagraph:
            'Mit unserem Influencer Marketing Fest wollen wir Licht ins Dunkel bringen und das Thema aus verschiedenen Perspektiven durchleuchten – unterstützt von den angesagtesten Speakern und Experten zu TikTok, Instagram, YouTube und LinkedIn.',
          thirdParagraph:
            'Ganz gleich, ob du ein Markenvertreter, Marketer oder Creator bist – beim Influencer Marketing Fest wirst du lernen und verstehen, wie nachhaltiges Influencer Marketing plattformübergreifend funktioniert.',
          fourthParagraph:
            'Das Influencer Marketing Fest mit renommierten Speakern aus verschiedenen Branchen wird dir eine Vielzahl wertvoller Einsichten zum Einsatz von Influencer Marketing in Theorie und Praxis geben.',
          list: [
            'Unsere Redner sind Creator, Marketingspezialisten und Markenvertreterinnen, die die Social-Media-Landschaft in den letzten Jahren maßgeblich geprägt haben.',

            'Das Webinar wird Diskussionen zum Thema Influencer Marketing auf Social-Media-Plattformen wie YouTube, Instagram, TikTok und LinkedIn beinhalten.',

            'Zusätzlich werfen wir einen Blick auf das Influencer Marketing im B2B-Bereich und auf Influencer-Events.',
          ],
        },
      },
      refs: 'References',
      inTheNews: 'In The News',
      speakers: {
        title: 'Speakers',
        moderators: {
          title: 'Hosts',
          list: [
            {
              firstName: 'Adil',
              lastName: 'Sbai',

              links: {
                instagram: 'https://www.instagram.com/sbaiderman/?hl=en',
                tiktok: 'https://www.tiktok.com/@sbaiderman',
                linkedin: 'https://at.linkedin.com/in/adil-sbai-500b75117',
                youtube: 'https://www.youtube.com/channel/UCl4BRUbFKQTt-NAlqd9XE2g',
              },
              roles: [
                'CEO und Gründer von weCreate, der ersten datenbasierterten TikTok Agentur',
                'Autor der TikTok-Bibel ',
                'Manager von Younes Zarou, dem größten Creator Europas',
                'TikTok-Marketing- und Strategie-Experte',
              ],
              imagename: 'adil-s.jpg',
            },
            {
              firstName: 'Sarah',
              lastName: 'Emmerich',
              links: {
                instagram: 'http://www.instagram.com/sarahemmerich',
                linkedin: 'https://de.linkedin.com/in/sarahemmerichinfluencer',
              },
              roles: [
                'Expertin für Influencer Marketing',
                'Social-Media-Native und Creator',
                'Podcast Host von “Zwischen Generation Y und Z”',
                'Strategin & Beraterin rund um das Thema Social Media mit Fokus auf Influencer Marketing & Personal Branding',
              ],
              imagename: 'sarah-e.jpg',
            },
          ],
        },
        brands: {
          title: 'Brands',
          list: [
            {
              firstName: 'Johannes',
              lastName: 'Kliesch',
              links: {
                linkedin: 'https://www.linkedin.com/in/johannes-kliesch/',
                instagram: 'https://www.instagram.com/johannes.snocks/?hl=de',
                youtube: 'https://www.youtube.com/channel/UCH5aAB27lz57Ca7MlJya9QA',
              },
              roles: [
                'Gründer und Geschäftsführer von SNOCKS',
                'E-Commerce Speaker für Amazon FBA und Shopify',
                'Zweifach ausgezeichnet als Forbes 30 Under 30',
              ],
              imagename: 'johannes-k.jpg',
            },
          ],
        },
        creators: {
          title: 'Creators',
          list: [
            {
              firstName: 'Max',
              lastName: 'Klockenhoff',
              links: {
                linkedin: 'https://www.linkedin.com/in/max-klockenhoff/',
                instagram: 'https://www.instagram.com/weltfaktende/?hl=en',
                youtube: 'https://www.youtube.com/channel/UCohZCZUm6uP6OdDQuRsMOIw',
              },
              roles: [
                'Influencer Relations & Social Media Berater',
                'TikTok-Experte',
                'Ex-Creator (YouTube & Instagram)',
              ],
              imagename: 'max-k.jpg',
            },
            {
              firstName: 'Herr',
              lastName: 'Anwalt',
              links: {
                instagram: 'https://www.instagram.com/herr_anwalt/?hl=en',
                tiktok: 'https://www.tiktok.com/@herranwalt',
                youtube: 'https://www.youtube.com/channel/UCOfQ0nZG7YClwNSaL-xULKQ',
              },
              roles: [
                'Rechtsanwalt und Fachanwalt bei Besler & Walter & Keuneke',
                'TikTok-Creator mit 2,5 Millionen Followern',
                'Mercedes-Benz Kooperationspartner',
              ],
              imagename: 'herranwalt.jpg',
            },
          ],
        },
        marketers: {
          title: 'Marketers',
          list: [
            {
              firstName: 'Céline Flores',
              lastName: 'Willers',
              links: {
                linkedin: 'https://www.linkedin.com/in/céline-flores-willers/',
                instagram: 'https://www.instagram.com/celine.flores/?hl=de',
              },
              roles: [
                'Gründerin der The People Branding Company ',
                'Top 25 LinkedIn Influencer in Dach („Top Voice 2018/19“)',
                'Personal Branding Strategin',
                'Miss Universe Germany 2018',
              ],
              imagename: 'celine-f.jpg',
            },
            {
              firstName: 'Neil',
              lastName: 'Heinisch',
              links: {
                instagram: 'https://www.instagram.com/neilheinisch/?hl=en',
                twitter: 'https://twitter.com/NeilHeinisch',
                linkedin: 'https://www.linkedin.com/in/neilheinisch/',
              },
              roles: [
                'Mitbegründer von PlayTheHype ',
                'Generation Z-Berater und Startup Teen',
                'Marketing- und TikTok-Enthusiast',
              ],
              imagename: 'neil-h.jpg',
            },
            {
              firstName: 'Lina',
              lastName: 'Arnold',
              links: {
                linkedin: 'https://www.linkedin.com/in/linaeinberger/',
              },
              roles: [
                'Geschäftsführerin bei JOLI BERLIN',
                'Expertin für datengesteuerte Influencer Marketingkampagnen',
              ],
              imagename: 'lina-a.jpg',
            },
            // {
            //   firstName: 'Elisa',
            //   lastName: 'Buhr',
            //   links: {
            //     linkedin: 'https://www.linkedin.com/in/elisa-buhr-927755b4',
            //   },
            //   roles: [
            //     'Marketing Managerin bei Katjes Fassin GmbH + Co. KG',
            //     'Marketing Managerin für die Marken Ahoj-Brause, SALLOS und Hemptastic',
            //     'Der Geist hinter Ahoj-Brauses bisher erfolgreichster Kampagne',
            //   ],
            //   imagename: 'elisa-b.jpg',
            // },
            {
              firstName: 'Hendrik',
              lastName: 'Martens',
              links: {
                linkedin: 'https://www.linkedin.com/in/hendrikmartens/',
                instagram: 'https://www.instagram.com/ideealo/?hl=en',
              },
              roles: [
                'CMO von flow:fwd, dem Artist-Management von Simon Unge, Inscope21, UnsympathischTV, Varion u.a.',
                'Moderator des Webinars “BIZZfluencer” zum Thema Business Influencers ',
              ],
              imagename: 'hendrik-m.jpg',
            },
            {
              firstName: 'Arian',
              lastName: 'Ruß',
              links: {
                linkedin: 'https://www.linkedin.com/in/arianruß/',
                twitter: 'https://twitter.com/arian_swinx',
              },
              roles: ['CMO von swinx', 'B2B Influencer Service'],
              imagename: 'arian-r.jpg',
            },
            {
              firstName: 'Alina',
              lastName: 'Ludwig',
              links: {
                linkedin: 'https://www.linkedin.com/in/alinaludwig/',
                instagram: 'https://www.instagram.com/laalinali/',
              },
              roles: [
                'Senior Strategy Consultant bei ODALINE',
                'Podcast Host von “Influence!” ',
                'Brand Strategist & Influencer Marketing Expertin',
                'Autorin des Buches “Influence!”',
              ],
              imagename: 'alina-l.jpg',
            },
            // {
            //   firstName: 'Leon',
            //   lastName: 'Jungfleisch',
            //   links: {
            //     linkedin: 'https://www.linkedin.com/in/leon-jungfleisch-52bb8a17a/',
            //   },
            //   roles: ['Gründer & Geschäftsführer PEECES | E-Commerce'],
            //   imagename: 'leon-j.jpg',
            // },
          ],
        },
      },
      timeTable: {
        title: 'Timetable',
        content: 'Inhalt',
        list: [
          {
            time: 'Part 1',
            speaker: 'Sarah Emmerich (30 min)',
            label: 'Die Geschichte des Influencer Marketings',
          },
          {
            time: 'Part 2',
            speaker: 'Sarah Emmerich & Alina Ludwig  (90 min)',
            label: 'Kampagnenmanagement: Sourcing, Verhandlungen, Briefing, Reporting',
          },
          {
            time: 'Part 3',
            speaker: 'Arian Ruß (30 min)',
            label: 'B2B Influencer: Die neue Geheimwaffe im Social Selling auf LinkedIn?',
          },
          {
            time: 'Part 4',
            speaker: 'Céline Flores Willers (30 min)',
            label: 'Q&A: Wie kann Influencer Marketing für B2B und B2C Unternehmen auf LinkedIn funktionieren?',
          },
          {
            time: 'Part 5',
            speaker: 'Johannes Kliesch (30 min)',
            label: 'Der Value von Performance Marketing für Influencer Marketing',
          },
          {
            time: 'Part 6',
            speaker: 'Sarah Emmerich (45 min)',
            label: 'Influencer Events: Modelle und Best Practices',
          },
          {
            time: 'Part 7',
            speaker: 'Björn Wenzel (15min)',
            label: 'Die Wirkung von Influencer-Marketing: Marktforschung, Follower-Sturkturanalyse, Langzeiteffekte',
          },
          {
            time: 'Part 8',
            speaker: 'Hendrik Martens (30 min)',
            label: 'Creative Influencer Marketing auf YouTube: Best Practices national & international',
          },
          {
            time: 'Part 9',
            speaker: 'Max Klockenhoff (30 min)',
            label: 'Wann macht Influencer Marketing auf TikTok Sinn',
          },
          {
            time: 'Part 10',
            speaker: 'Adil Sbai (30 min)',
            label: 'Influencer Marketing auf TikTok: Best Practices national & international',
          },
          {
            time: 'Part 11',
            speaker: 'Lina Arnold & Jonas Kunow (30 min)',
            label: 'TikTok-Kampagnen mit ROI-Fokus',
          },
          { time: 'Part 12', speaker: 'Neil Heinisch (30 min)', label: 'Influencer Marketing für die Gen Z' },
          { time: 'Part 13', speaker: 'Herr Anwalt (30 min)', label: 'Gutes Influencer Marketing - aus Creator-Sicht' },
          { time: 'Outro', speaker: 'Sarah Emmerich & Adil Sbai', label: 'Finale' },
        ],
      },
      about: {
        title: 'about',
        desc:
          'weCreate ist die erste datenbasierte TikTok-Agentur. Wir konzentrieren uns darauf, unseren Kunden Beratung, Daten, Marktforschung und Strategie unter Verwendung unserer eigenen Creator-Datenbank, influData, anzubieten.',
        link: 'Go To WebSite',
      },
      successPurchase:
        'Vielen Dank. Du erhältst in Kürze eine Rechnung und die Zugangsdaten via Mail. Wir wünschen dir viel Spaß mit unserer Masterclass!',
    },
  },
}
